import { Container } from "@mui/material";
import React from "react";
import EndOfCampaignText from "../src/components/EndOfCampaignText";
import defaultLanguage from "../src/translations/defaultLanguage";
import { Lang } from "../src/translations/Lang";

export default function Custom404() {
  const [language, setLanguage] = React.useState(defaultLanguage);

  React.useEffect(() => {
    const lang = navigator.language;
    if (lang === "de-DE" || lang === "de-CH" || lang === "de") {
      setLanguage(Lang.de);
    }
    if (lang === "fr-FR" || lang === "fr" || lang === "fr-CH") {
      setLanguage(Lang.fr);
    }
    if (lang === "it-IT" || lang === "it" || lang === "it-CH") {
      setLanguage(Lang.it);
    }
    if (lang === "en-US" || lang === "en" || lang === "en-GB") {
      setLanguage(Lang.en);
    }
  }, []);

  return (
    <Container maxWidth='sm' sx={{ my: 4 }}>
      <EndOfCampaignText language={language} />
    </Container>
  );
}
